<template>
  <div>
    <div :class="$style.set">
      <div :class="$style.title">基础信息</div>
      <div :class="$style.content">
        <div class="layout mb-20">
          <div class="label">
            <span class="required">*</span>
            地图中心
          </div>
          <div class="value">
            <BjInput v-model="data.center_address" class="cursor map" placeholder="请选择中心位置">
              <div slot="suffix">
                <a-popover title="位置信息">
                  <template slot="content">
                    <p>经度：{{ data.center_longitude }}</p>
                    <p>纬度：{{ data.center_latitude }}</p>
                    <p>地址：{{ data.center_real_address }}</p>
                  </template>
                  <i
                    v-if="data.center_real_address"
                    style="vertical-align: -6px"
                    class="ri-question-line location-icon"
                  />
                </a-popover>
                <span :class="$style.location" @click="onMapSelect()"
                  ><i class="ri-map-pin-line mr-5" />
                  <span>选择位置</span>
                </span>
              </div>
            </BjInput>
            <div class="tip">请设置地图的中心位置，地图打开将默认以此位置为中心显示。</div>
          </div>
        </div>
        <div class="layout mb-20">
          <div class="label">
            <span class="required">*</span>
            地图层级
          </div>
          <div class="value">
            <BjSelect
              v-model="data.level"
              key-field="id"
              placeholder="请选择地图层级"
              value-field="id"
              label-field="name"
              show-all
              :options="levelData"
            />
            <div class="tip">请设置地图的显示层级，层级越大地图显示越放大。</div>
          </div>
        </div>
        <div class="layout">
          <div class="label">
            <span class="required">*</span>
            地图样式
          </div>
          <div class="value" :class="$style.styleBox">
            <div v-for="item in styleData" :key="item.id" :class="$style.styleItem" @click="onStyle(item)">
              <div :class="$style.styleImg">
                <div v-if="data.style === item.id" :class="$style.styleActive">
                  <i class="ri-check-line" />
                </div>
                <img :src="oss + item.img" />
              </div>
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.footer">
      <BjButton v-permission="'resources.poc.map.config-edit'" class="mr-10" type="primary" @click="onSave()">
        <i class="ri-save-line left" />
        立即保存
      </BjButton>
    </div>
    <BjMap :visible.sync="mapVisible" @change="mapChange" />
  </div>
</template>

<script>
import BjMap from '@/components/BjMap.vue'
import { resourceMapService } from '@/service'

const service = new resourceMapService()

export default {
  name: 'Home',
  components: {
    BjMap,
  },
  data() {
    return {
      data: {
        level: 4,
        style: '',
      },
      levelData: [],
      mapVisible: false,
      styleData: [
        {
          img: '/manage/media/map/normal.png',
          id: 'normal',
          name: '标准',
        },
        {
          img: '/manage/media/map/blue.png',
          id: 'blue',
          name: '靛青蓝',
        },
        {
          img: '/manage/media/map/darkblue.png',
          id: 'darkblue',
          name: '极夜蓝',
        },
        {
          img: '/manage/media/map/fresh.png',
          id: 'fresh',
          name: '草色青',
        },
        {
          img: '/manage/media/map/graffiti.png',
          id: 'graffiti',
          name: '涂鸦',
        },
        {
          img: '/manage/media/map/grey.png',
          id: 'grey',
          name: '雅士灰',
        },
        {
          img: '/manage/media/map/light.png',
          id: 'light',
          name: '月光银',
        },
        {
          img: '/manage/media/map/whitesmoke.png',
          id: 'whitesmoke',
          name: '远山黛',
        },
        {
          img: '/manage/media/map/wine.png',
          id: 'wine',
          name: '酱籽',
        },
      ],
    }
  },
  created() {
    this.levelData = []
    for (let index = 4; index < 16; index++) {
      this.levelData.push({
        name: index + '级',
        id: index,
      })
    }
    this.getInfo()
  },
  methods: {
    async getInfo() {
      const { data } = await service.getSet()
      this.data = data
    },
    onStyle(item) {
      this.data.style = item.id
    },
    mapChange(map) {
      this.data.center_address = map.location
      this.data.center_real_address = map.location
      this.data.center_longitude = map.longitude
      this.data.center_latitude = map.latitude
      this.$forceUpdate()
    },
    onMapSelect() {
      this.mapVisible = true
    },
    async onSave() {
      try {
        await service.editSet({
          ...this.data,
        })
        this.$message.success('保存成功')
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.set {
  padding: 20px;
  background: #fff;
  position: relative;

  .title {
    font-size: 16px;
    color: #000;
    margin-bottom: 20px;
  }

  .content {
    width: 740px;

    :global {
      .ant-form-item {
        margin-bottom: 10px;
      }
    }

    .location {
      display: inline-block;
      width: 84px;
      height: 28px;
      color: #fff;
      font-size: 12px;
      line-height: 28px !important;
      text-align: center;
      background: @primary-color;
      border-radius: 6px;
      cursor: pointer;

      i {
        font-size: 16px;
        vertical-align: -3px;
      }
    }

    .style-box {
      display: flex;
      flex-wrap: wrap;

      .style-item {
        position: relative;
        width: 60px;
        margin-right: 20px;
        padding-bottom: 20px;
        cursor: pointer;

        .style-img {
          width: 60px;
          height: 60px;
          border-radius: 100%;

          img {
            width: 60px;
            height: 60px;
            object-fit: cover;
            border-radius: 100%;
          }
        }

        .style-active {
          position: absolute;
          top: 0;
          left: 0;
          text-align: center;
          line-height: 60px;
          border-radius: 100%;
          width: 60px;
          height: 60px;
          background: rgba(0, 0, 0, 0.4);

          i {
            font-size: 30px;
            color: #fff;
          }
        }

        p {
          margin-top: 10px;
          margin-bottom: 0;
          text-align: center;
          font-size: 13px;
          color: #000;
        }
      }
    }
  }
}

.footer {
  position: absolute;
  bottom: 0;
  background: #fff;
  left: 20px;
  width: calc(100% - 40px);
  height: 60px;
  padding-left: 20px;
  line-height: 60px;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);
}
</style>
